import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOneBlog } from "../../Redux/Actions/BlogActions";

const BlogDetailsHook = (BlogId) => {
  const dispatch = useDispatch();
  const res = useSelector((state) => state.BlogsData.oneBlog);
  let Blog = {};
  if (res) Blog = res.data;
  useEffect(() => {
    if (BlogId) dispatch(getOneBlog(BlogId.id));
  }, [dispatch, BlogId]);
  return [Blog];
};

export default BlogDetailsHook;
