import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Bank,
  BriefcaseFill,
  BuildingFillUp,
  CurrencyExchange,
  Display,
  FileEarmarkTextFill,
} from "react-bootstrap-icons";

const OurServies = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = isMobile ? "0px" : "120px";
  return (
    <section
      id="services-list"
      className="services-list"
      style={{ direction: "rtl" }}
    >
      <Container className="services-margin-ar" data-aos="fade-up">
        <div className="section-header">
          <h2>خدماتنا</h2>
        </div>

        <Row
          className="gy-4 gx-5 ourServes"
          style={{ marginRight: containerStyle }}
        >
          {/* استشارات استثمارية */}
          <Col
            className="col-lg-4 col-md-12 service-item d-flex"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="icon flex-shrink-0">
              <BriefcaseFill
                style={{ fontSize: "22px", color: "#C0A380", margin: "0 2px" }}
              />
            </div>
            <div>
              <h4 className="title">
                <a href="##" className="stretched-link">
                  استشارات استثمارية
                </a>
              </h4>
              <p className="description"></p>
            </div>
          </Col>

          {/* صناديق استثمارية */}
          <Col
            className="col-lg-4 col-md-12 service-item d-flex"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="icon flex-shrink-0">
              <Bank
                style={{ fontSize: "22px", color: "#C0A380", margin: "0 0px" }}
              />
            </div>
            <div>
              <h4 className="title">
                <a href="##" className="stretched-link">
                  صناديق استثمارية
                </a>
              </h4>
              {/* <p className="description"></p> */}
            </div>
          </Col>

          {/* استشارات مالية */}
          <Col
            className="col-lg-4 col-md-12 service-item d-flex"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="icon flex-shrink-0">
              <CurrencyExchange
                style={{ fontSize: "22px", color: "#C0A380", margin: "0 2px" }}
              />
            </div>
            <div>
              <h4 className="title">
                <a href="##" className="stretched-link">
                  استشارات مالية
                </a>
              </h4>
              {/* <p className="description"></p> */}
            </div>
          </Col>

          {/* استشارات وتدريب */}
          <Col
            className="col-lg-4 col-md-12 service-item d-flex"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="icon flex-shrink-0">
              <BuildingFillUp
                style={{ fontSize: "22px", color: "#C0A380", margin: "0 2px" }}
              />
            </div>
            <div>
              <h4 className="title">
                <a href="##" className="stretched-link">
                  استشارات وتدريب الشركات الناشئة
                </a>
              </h4>
              {/* <p className="description"></p> */}
            </div>
          </Col>

          {/* استشارات قانونية */}
          <Col
            className="col-lg-4 col-md-12 service-item d-flex"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="icon flex-shrink-0">
              <FileEarmarkTextFill
                style={{ fontSize: "22px", color: "#C0A380", margin: "0 2px" }}
              />
            </div>
            <div>
              <h4 className="title">
                <a href="##" className="stretched-link">
                  استشارات قانونية
                </a>
              </h4>
              {/* <p className="description"></p> */}
            </div>
          </Col>

          {/* استشارات برمجية */}
          <Col
            className="col-lg-4 col-md-12 service-item d-flex"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="icon flex-shrink-0">
              <Display
                style={{ fontSize: "22px", color: "#C0A380", margin: "0 2px" }}
              />
            </div>
            <div>
              <h4 className="title">
                <a href="##" className="stretched-link">
                  استشارات برمجية وحلول إلكترونية
                </a>
              </h4>
              {/* <p className="description"></p> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurServies;
