import React from "react";
import Sidenav from "../../Components/Dashboard/uitilis/Sidenav";
import AdminNavBar from "../../Components/Dashboard/uitilis/AdminNavBar";
import "../../Components/Dashboard/uitilis/uitilis.css";

const DashboardPage = () => {
  return (
    <div>
      <Sidenav />
      <div className="D_container">
        <AdminNavBar />
      </div>
    </div>
  );
};

export default DashboardPage;
