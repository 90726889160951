import React from "react";
import "../.././assets/AdminCss/Auth/Login.css";
import { ToastContainer } from "react-toastify";
import LogInHook from "../../Hooks/AuthHooks/LogInHook";
const AuthForm = () => {
  const [userName, password, onChangeUserName, onChangePassWord, onSubmit] =
    LogInHook();
  return (
    <div className="SignInForm">
      <h1>تسجيل الدخول</h1>
      <form>
        <div>
          <input
            type="text"
            value={userName}
            onChange={onChangeUserName}
            id="username"
            name="username"
            placeholder="اسم المستخدم"
          />
        </div>
        <div>
          <input
            type="password"
            value={password}
            onChange={onChangePassWord}
            id="password"
            name="password"
            placeholder="كلمة المرور"
          />
        </div>

        <button onClick={onSubmit} type="submit" className="S-button">
          إرسال
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AuthForm;
