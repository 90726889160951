import React, { useEffect, useState } from "react";
import BlogPagination from "./BlogPagination";
import "../../assets/UserCss/css/Blogs/BlogPost.css";
import { CalendarCheck } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import AllBlogsHook from "../../Hooks/BlogsHook/All-Blogs-Hook";
import BlogCategories from "./BlogCategories";
import { useDispatch } from "react-redux";
import getAllBlogs from "../../Redux/Actions/BlogActions";
import { htmlToText } from "html-to-text";

const BlogPost = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const dispatch = useDispatch();
  const [BlogsRes, PageCount, getBlogsWithPage] = AllBlogsHook();
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleSearch = () => {
    dispatch(getAllBlogs(searchKeyword));
  };

  const cleanText = (html, maxLength) => {
    // Convert HTML to plain text
    const text = htmlToText(html, { wordwrap: 130 });
    const cleanedText = text
      .trim()
      .split("\n")
      .filter((line) => line.trim() !== "")
      .join(" ");

    const truncatedText =
      cleanedText.length > maxLength
        ? cleanedText.substring(0, maxLength) + "..."
        : cleanedText;
    return truncatedText;
  };

  if (isMobile) {
    return (
      <div className="user_Blogs d-flex">
        <div className="col-12" style={{ direction: "rtl" }}>
          <div className="Search_bar" style={{ width: "100%" }}>
            <h2>ابحث عن المقالة</h2>
            <div className="search-wrapper">
              <input
                type="search"
                placeholder="ابحث هنا"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <i className="fa fa-search"></i>
            </div>
            <button onClick={() => handleSearch()}>ابحث</button>
          </div>
          <BlogCategories />

          <div className="row gy-5 posts-list pt-3">
            {BlogsRes && BlogsRes.data
              ? BlogsRes.data?.map((item, index) => (
                  <div className=" post" key={index}>
                    <div className="post-img">
                      <Link to={`/Blogs/${item._id}`}>
                        <img
                          src={item.imageCover}
                          alt=""
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="content">
                      {" "}
                      <h2 className="title" style={{ fontSize: "14px" }}>
                        <Link to={`/Blogs/${item._id}`}>{item.titleAr}</Link>
                      </h2>
                      <div className="text">
                        <p>
                          {cleanText(item.descriptionAr, 200)}
                          ...
                        </p>
                      </div>
                      <div className="d-flex date_more">
                        <div className="postDate">
                          <CalendarCheck style={{ marginLeft: "5px" }} />
                          <time datatime="2022-01-01">
                            {item.createdAt.substring(0, 10)}
                          </time>
                        </div>
                        <div className="read-more mt-auto align-self-end">
                          <Link to={`/Blogs/${item._id}`}>
                            المزيد <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
          <BlogPagination PageCount={PageCount} onPress={getBlogsWithPage} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="user_Blogs d-flex">
        <div
          className="col-8"
          data-aos="fade-up"
          data-aos-delay="200"
          style={{ direction: "rtl" }}
        >
          <div className="Search_bar">
            <h2>ابحث عن المقالة</h2>
            <div className="search-wrapper">
              <input
                type="search"
                placeholder="ابحث هنا"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <i className="fa fa-search"></i>
            </div>
            <button onClick={() => handleSearch()}>ابحث</button>
          </div>

          <div className="row gy-5 posts-list">
            {BlogsRes && BlogsRes.data
              ? BlogsRes.data?.map((item, index) => (
                  <div className=" post" key={index}>
                    <div className="post-img">
                      <Link to={`/Blogs/${item._id}`}>
                        <img
                          src={item.imageCover}
                          alt=""
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="content">
                      {" "}
                      <h2 className="title" style={{ fontSize: "14px" }}>
                        <Link to={`/Blogs/${item._id}`}>{item.titleAr}</Link>
                      </h2>
                      <div className="text">
                        <p>
                          {cleanText(item.descriptionAr, 180)}
                          ...
                        </p>
                      </div>
                      <div className="d-flex date_more">
                        <div className="postDate">
                          <CalendarCheck style={{ marginLeft: "5px" }} />
                          <time datatime="2022-01-01">
                            {item.createdAt.substring(0, 10)}
                          </time>
                        </div>
                        <div className="read-more mt-auto align-self-end">
                          <Link to={`/Blogs/${item._id}`}>
                            المزيد <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
          <BlogPagination PageCount={PageCount} onPress={getBlogsWithPage} />
        </div>
        <BlogCategories />
      </div>
    );
  }
};

export default BlogPost;
