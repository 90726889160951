import { createAsyncThunk } from "@reduxjs/toolkit";
import useGetData from "../../Hooks/Api/useGetData";
import useDeleteData from "../../Hooks/Api/useDeleteData";
import { useUpdateDataWithImg } from "../../Hooks/Api/useUpdateData";
import usePostData from "../../Hooks/Api/usePostData";
import data from "../../data";

// Create Blog
export const CreateBlog = createAsyncThunk("Blog/Add", async (data) => {
  const res = await usePostData(`/api/blog/`, data);
  return res;
});

// get All Blogs
export const getAllBlogs = createAsyncThunk("Blogs/get", async (keyword) => {
  const url = keyword ? `/api/blog?keyword=${keyword}` : "/api/blog";
  // Fetch the data
  const res = await useGetData(url);
  return res.data;
});

// get  Blogs By Category
export const getBlogsByCategory = createAsyncThunk(
  "BlogsByCategory/get",
  async (categoryId) => {
    const res = await useGetData(`/api/blog?categoryId=${categoryId}`);
    return res.data;
  }
);

// get Blogs of Specifie Page
export const getBlogsOfPage = createAsyncThunk(
  "PageBlogs/get",
  async (page) => {
    const res = await useGetData(`/api/blog?page=${page}`);
    return res.data;
  }
);
// get one Blog
export const getOneBlog = createAsyncThunk("OneBlog/get", async (id) => {
  const res = await useGetData(`/api/blog/${id}`);
  return res.data;
});

// Delete Blog
export const DeleteBlog = createAsyncThunk("OneBlog/delete", async (id) => {
  const res = await useDeleteData(`/api/blog/${id}`);
  return res.status;
});
// update Blog
export const EditBlog = createAsyncThunk(
  "OneBlog/update",
  async ({ id, data }) => {
    const res = await useUpdateDataWithImg(`/api/blog/${id}`, data);
    return res;
  }
);

export default getAllBlogs;
