import { createAsyncThunk } from "@reduxjs/toolkit";
import usePostData from "../../Hooks/Api/usePostData";

// LogIn User
export const userLogIn = createAsyncThunk("Auth/Login", async (data) => {
  const res = await usePostData(`/api/auth/login`, data);
  return res.data;
});

export default userLogIn;
