import baseURL from "../../api/baseURL";

const usePostData = async (url, params) => {
  try {
    const res = await baseURL.post(url, params);
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default usePostData;
