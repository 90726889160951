import "./uitilis.css";

function AdminNavBar() {
  return (
    <div className="AdminNavbar">
      <p>Dashboard</p>
    </div>
  );
}

export default AdminNavBar;
