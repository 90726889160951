import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs, getBlogsOfPage } from "../../Redux/Actions/BlogActions";

const AllBlogsHook = () => {
  const dispatch = useDispatch();
  const BlogsRes = useSelector((state) => state.BlogsData.Blogs);
  let PageCount = "";
  if (BlogsRes?.Pages) PageCount = BlogsRes.Pages;
  // get Blogs of Specific Page
  const getBlogsWithPage = async (page) => {
    await dispatch(getBlogsOfPage(page));
  };
  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);
  return [BlogsRes, PageCount, getBlogsWithPage];
};

export default AllBlogsHook;
