import { createAsyncThunk } from "@reduxjs/toolkit";
import useGetData from "../../Hooks/Api/useGetData";
import useDeleteData from "../../Hooks/Api/useDeleteData";
import useUpdateData from "../../Hooks/Api/useUpdateData";
import usePostData from "../../Hooks/Api/usePostData";

// Create Category
export const AddCategory = createAsyncThunk("Category/Add", async (data) => {
  const res = await usePostData(`/api/category/`, data);
  return res;
});

// get All Categories
export const getAllCategories = createAsyncThunk(
  "Category/get",
  async (limit) => {
    const res = await useGetData(`/api/category`);
    return res.data;
  }
);

// get one Category
export const getOneCategory = createAsyncThunk(
  "OneCategory/get",
  async (id) => {
    const res = await useGetData(`/api/category/${id}`);
    return res.data;
  }
);

// Delete Category
export const DeleteCategory = createAsyncThunk(
  "OneCategory/delete",
  async (id) => {
    const res = await useDeleteData(`/api/category/${id}`);
    return res;
  }
);
// update Category
export const EditCategory = createAsyncThunk(
  "OneCategory/update",
  async ({ id, data }) => {
    console.log(`id ${id}`);

    const res = await useUpdateData(`/api/category/${id}`, data);
    return res;
  }
);

export default getAllCategories;
