import React from "react";
import ServicesMain from "../../Components/Serviec/ServicesMain";
import OurServies from "../../Components/Uitily/OurServies";
import ServicesCards from "../../Components/Serviec/ServicesCards";
import Navbar1 from "../../Components/Uitily/Navbar";
import Footer from "../../Components/Uitily/Footer";
const ServicesPage = () => {
  return (
    <div style={{ direction: "rtl" }}>
      <Navbar1 />
      <div className="page-services">
        <main id="main">
          <ServicesMain />
          <OurServies />
          <ServicesCards />
          {/* <ServicesTestimonials /> */}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default ServicesPage;
