import React, { useEffect, useState } from "react";
import "../../assets/UserCss/css/main.css";
import getAllCategories from "../../Redux/Actions/CategoryAction";
import { useDispatch, useSelector } from "react-redux";
import { getBlogsByCategory } from "../../Redux/Actions/BlogActions";

const BlogCategories = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.CategoryData.getCategories);
  useEffect(() => {
    dispatch(getAllCategories());
  }, []);
  const getCategoryBlogs = (categoryId) => {
    if (categoryId) {
      dispatch(getBlogsByCategory(categoryId));
    }
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  return (
    <div
      className={`text-end sidebar-item categories ${
        isMobile ? "col-12" : "col-3"
      }`}
    >
      <h3 className="sidebar-title">تصنيف المقالات</h3>
      <ul className="mt-3">
        {categories?.data?.map((category) => (
          <li key={category._id} onClick={() => getCategoryBlogs(category._id)}>
            {category.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogCategories;
