import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import img from "../../assets/img/Aboutus22.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

const WhyChooseUs = () => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 600) {
        setMobile(false);
      } else {
        setMobile(true);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section id="why-us" className="why-us">
      <Container data-aos="fade-up">
        <div className="section-header">
          <h2>من نحن</h2>
        </div>

        <Row
          className=" justify-content-between g-0"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <Col
            xl={5}
            className=" img-bg"
            style={{
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              borderRadius: "10px",
            }}
          ></Col>

          <Col xl={6} className="position-relative">
            {mobile ? (
              <>
                <Swiper
                  spaceBetween={100}
                  slidesPerView={1}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  loop={true}
                  pagination={{ clickable: true }}
                  modules={[Pagination, Navigation]}
                >
                  <SwiperSlide>
                    <div className="item " style={{ direction: "rtl" }}>
                      <h3 style={{ marginBottom: "30px" }}>من نحن</h3>

                      <p style={{ textAlign: "justify" }}>
                        شركة متخصصة بإدارة الاستثمار في السوق التركي، وتأسيس
                        الشركات، وتقديم الاستشارات المالية والقانونية
                        والتكنولوجية للشركات والأفراد تأسست الشركة عام 2013
                        كشركة متخصصة باستيراد وتصدير المنتجات الإلكترونية، وفي
                        عام 2014 تحول لشركة تعمل على إبداع الأفكار وتحويلها
                        لمشاريع ومن ثم لشركات مساهمة وطرحها للاستثمار، وتعتبر
                        شركة NOONTEK المتخصصة بالتكنولوجيا أول شركة مساهمة تم
                        إطلاقها عام 2014 وفي عام 2020 تحولت الشركة للاستثمار
                        المؤسساتي، بتأسيس صناديق الاستثمار والحصول على
                        الاستثمارات من خارج تركيا، وكان لجودي في الاستثمار دور
                        كبير في تطوير و تأسيس العديد من المشاريع الاستثمارية في
                        تركيا و حصول المستثمرين على الجنسية التركية.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="item " style={{ direction: "rtl" }}>
                      <h3 style={{ marginBottom: "30px" }}>مهمتنا</h3>
                      <p style={{ textAlign: "justify" }}>
                        تأمين استثمار آمن للمساهمين،وابتكار الافكار الريادية في
                        عالم الأعمال وتحويلها لشركات مساهمة، ودعم الشركات
                        الناشئة بالاستشارات والتمويل
                      </p>
                      <h3 style={{ marginBottom: "30px" }}>رؤية الشركة</h3>
                      <p style={{ textAlign: "justify" }}>
                        أن نكون شركة رائدة بإدارة الاستثمار في تركيا والشرق
                        الأوسط، وتطوير أدوات الاستثمار وأنواعه، ودعم الشركات
                        الناشئة
                      </p>
                      <h3 style={{ marginBottom: "30px" }}>قِيَمُنَا</h3>
                      <p style={{ textAlign: "center" }}>
                        مصداقية, شفافية, أمان , استدامة
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>

                <div className="swiper-button-next swiper-button-white"></div>
                <div className="swiper-button-prev swiper-button-white"></div>
              </>
            ) : (
              <div className="whyus_info">
                <div className="item " style={{ direction: "rtl" }}>
                  <h3 style={{ marginBottom: "30px" }}>من نحن</h3>

                  <p style={{ textAlign: "justify" }}>
                    شركة متخصصة بإدارة الاستثمار في السوق التركي، وتأسيس
                    الشركات، وتقديم الاستشارات المالية والقانونية والتكنولوجية
                    للشركات والأفراد تأسست الشركة عام 2013 كشركة متخصصة باستيراد
                    وتصدير المنتجات الإلكترونية، وفي عام 2014 تحول لشركة تعمل
                    على إبداع الأفكار وتحويلها لمشاريع وشركات مساهمة لطرحها
                    للاستثمار، وتعتبر شركة NOONTEK المتخصصة بالتكنولوجيا أول
                    شركة مساهمة تم إطلاقها عام 2014، وفي عام 2020 تحولت الشركة
                    للاستثمار المؤسساتي، بتأسيس صناديق الاستثمار والحصول على
                    الاستثمارات من خارج تركيا، وكان لجودي دور كبير في تطوير و
                    تأسيس العديد من المشاريع الاستثمارية في تركيا و حصول
                    المستثمرين على الجنسية التركية.
                  </p>
                </div>
                <div className="item " style={{ direction: "rtl" }}>
                  <h3 style={{ marginBottom: "30px" }}>مهمتنا</h3>
                  <p style={{ textAlign: "justify" }}>
                    تأمين استثمار آمن للمساهمين،وابتكار الافكار الريادية في عالم
                    الأعمال وتحويلها لشركات مساهمة، ودعم الشركات الناشئة
                    بالاستشارات والتمويل
                  </p>
                </div>
                <div className="item " style={{ direction: "rtl" }}>
                  <h3 style={{ marginBottom: "30px" }}>رؤية الشركة</h3>
                  <p style={{ textAlign: "justify" }}>
                    أن نكون شركة رائدة بإدارة الاستثمار في تركيا والشرق الأوسط،
                    وتطوير أدوات الاستثمار وأنواعه، ودعم الشركات الناشئة
                  </p>
                </div>
                <div className="item " style={{ direction: "rtl" }}>
                  <h3 style={{ marginBottom: "30px" }}>قِيَمُنَا</h3>
                  <p style={{ textAlign: "justify" }}>
                    مصداقية, شفافية, أمان , استدامة
                  </p>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyChooseUs;
