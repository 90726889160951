import { useEffect, useState } from "react";
import axios from "axios";
import data from "../../data";

const GeolocationComponent = () => {
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [code, setCode] = useState("");
  const [, countryCodes] = data();

  useEffect(() => {
    const fetchGeolocationData = async () => {
      const apiKey = "b778d1fd357678"; // Replace with your IPinfo or IP Geolocation API key
      try {
        const response = await axios.get(`https://ipinfo.io?token=${apiKey}`);
        setCountry(response?.data?.country);
        setCity(response?.data?.city);
      } catch (error) {
        console.error("Error fetching geolocation data:", error);
      }
    };

    fetchGeolocationData();
  }, []);

  useEffect(() => {
    if (country && countryCodes[country]) {
      setCode(countryCodes[country]);
    }
  }, [country, countryCodes]);
  return { country, city, code };
};

export default GeolocationComponent;
