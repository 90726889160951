import React from "react";
import HomeMine from "../../Components/Home/HomeMine";
import WhyChooseUs from "../../Components/Uitily/WhyChooseUsSlider";
import OurServies from "../../Components/Uitily/OurServies";
import HomeYourBusiness from "../../Components/Home/HomeYourBusiness";
import ServicesCards from "../../Components/Serviec/ServicesCards";
import INVESTEDCOUNTRIES from "../../Components/Home/InvestedCountries";
import FundTypes from "../../Components/Home/FundTypes";
import Navbar1 from "../../Components/Uitily/Navbar";
import Footer from "../../Components/Uitily/Footer";
import HomePageSlider from "../../Components/Home/HomePageSlider/HomePageSlider";

const HomePage = () => {
  return (
    <>
      <Navbar1 />
      <div className="page-index">
        {/* <div className="heroar">
          <HomeMine />
        </div> */}
        <HomePageSlider />
        <WhyChooseUs />
        <div>
          <OurServies />
          <HomeYourBusiness />
          <INVESTEDCOUNTRIES />
          <ServicesCards />
          <FundTypes />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
