import React from "react";
import ContactNew from "../../Components/contactnew/contact";
import Navbar1 from "../../Components/Uitily/Navbar";
import Footer from "../../Components/Uitily/Footer";

const ContactPage = () => {
  return (
    <>
      <Navbar1 />
      <div className="page-contact">
        <ContactNew />
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
