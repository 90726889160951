import { createSlice } from "@reduxjs/toolkit";
import userLogIn from "../Actions/AuthActions";

const initialState = {
  LoggedUser: [],
  isloading: true,
};

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userLogIn.fulfilled, (state, action) => {
      state.LoggedUser = action.payload;
      state.isloading = false;
    });
  },
});

export default AuthSlice.reducer;
