import React from "react";
import { Link } from "react-router-dom";
import img from "../../assets/img/blog-header.webp";

const ServicesMain = () => {
  return (
    <div>
      <div
        className="breadcrumbs d-flex align-items-center"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="container position-relative d-flex flex-column align-items-center">
          <h2>خدماتنا</h2>
          <ol>
            <li>
              <Link to="/">الرئيسية</Link>
            </li>
            <li>خدماتنا</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default ServicesMain;
