import React from "react";
import Sidenav from "../uitilis/Sidenav";
import "./Blogs.css";
import AdminNavBar from "../uitilis/AdminNavBar";
import { Button, Modal } from "react-bootstrap";
import MultiImageInput from "react-multiple-image-input";
import AddBlogHook from "../../../Hooks/BlogsHook/AddBlogHook";
import { ToastContainer } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CategoryHook from "../../../Hooks/CategoriesHook/CategoryHook";

const AddBlog = () => {
  const [
    images,
    setImages,
    setImageCover,
    show,
    ArBlogTitle,
    ArBlogDesc,
    TrBlogTitle,
    TrBlogDesc,
    onArBlogTitleChange,
    onArBlogDescChange,
    onTrBlogTitleChange,
    onTrBlogDescChange,
    handleShow,
    handleClose,
    handleAddBlog,
    selectedCat,
    handleSelectCategory,
  ] = AddBlogHook();

  const { categories } = CategoryHook();

  return (
    <div>
      <div>
        <Sidenav />
      </div>
      <div class="D_container">
        <AdminNavBar />
        <div className="update-Blog">
          <h1>Add Blog</h1>
          <div className="Blog-boxes">
            <div className="Blog-Ar">
              <h4>التفاصيل العربية</h4>
              {/* Blog title */}
              <label htmlFor="title">عنوان المقالة</label>
              <input
                name="title"
                value={ArBlogTitle}
                onChange={onArBlogTitleChange}
                type="text"
                className="input-form d-block mt-3 px-3"
                placeholder="عنوان المقالة "
              />
              {/* Blog Description */}
              <h6>شرح المقالة</h6>

              <CKEditor
                editor={ClassicEditor}
                data={ArBlogDesc}
                onChange={onArBlogDescChange}
                config={{
                  toolbar: [
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "undo",
                    "redo",
                    "ltr",
                    "rtl",
                  ],
                  language: "ar",
                }}
              />
            </div>
            <div className="Blog-tr">
              <h4> Turkish language</h4>
              {/* Blog title */}
              <label htmlFor="title"> Blog başlığı</label>
              <input
                name="title"
                value={TrBlogTitle}
                onChange={onTrBlogTitleChange}
                type="text"
                className="input-form d-block mt-3 px-3"
                placeholder=" Blog başlığ "
              />
              {/* Blog Description */}
              <label htmlFor="Desc">Blog açıklaması </label>
              <CKEditor
                editor={ClassicEditor}
                data={TrBlogDesc}
                onChange={onTrBlogDescChange}
              />
            </div>
          </div>
          {/* Blog images */}
          <div className="d-flex">
            <MultiImageInput
              images={images}
              setImages={setImages}
              theme={"light"}
              allowCrop={false}
              max={4}
            />

            <div className=" w-25 h-25 m-auto">
              <h4>Image cover</h4>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setImageCover(e.target.files[0])}
              />
            </div>
          </div>

          <div className="category-dropdown w-50">
            <label htmlFor="category-select">اختر تصنيف</label>
            <select
              id="category-select"
              className="form-select"
              value={selectedCat}
              onChange={(e) => {
                handleSelectCategory(e.target.value);
              }}
            >
              <option value="">اختر تصنيف</option>
              {categories?.data?.map((category) => (
                <option
                  key={category._id}
                  value={category._id + "_" + category.name}
                >
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <Button variant="primary" onClick={handleShow}>
            confirm Add
          </Button>
          {/* Delete Button Modal */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Are you sure</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to add this Blog ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddBlog}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <ToastContainer />;
      </div>
    </div>
  );
};

export default AddBlog;
