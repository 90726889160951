import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getAllCategories, {
  AddCategory,
  DeleteCategory,
  EditCategory,
} from "../../Redux/Actions/CategoryAction";
import { toast } from "react-toastify";

const CategoryHook = () => {
  const dispatch = useDispatch();
  const categoryRes = useSelector((state) => state.CategoryData.categories);
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const categories = useSelector((state) => state.CategoryData.getCategories);
  useEffect(() => {
    dispatch(getAllCategories());
  }, [loading]);

  const handleClose = () => {
    setShowModal(false);
    setCategoryName("");
    setCategoryId(null);
    setIsEdit(false);
  };

  const handleShow = () => setShowModal(true);

  const handleDeleteShow = (category) => {
    setSelectedCategory(category);
    setShowDeleteModal(true);
  };

  const handleDeleteClose = async () => {
    setShowDeleteModal(false);
    setSelectedCategory(null);
  };

  const handleDeleteCategory = async (category) => {
    await dispatch(DeleteCategory(category._id));
    setShowDeleteModal(false);
    setLoading(false);
  };

  const handleEditCategory = (category) => {
    setIsEdit(true);
    setCategoryName(category.name);
    setCategoryId(category._id);
  };

  const handleEditClose = () => {
    setIsEdit(false);
    setShowModal(false);
    setCategoryName("");
  };

  const onNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShowModal(false);

    await dispatch(AddCategory({ name: categoryName }));
    setCategoryName(null);
    setLoading(false);
  };

  const handleConfirmEditCategory = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShowModal(false);

    const formData = { name: categoryName };
    await dispatch(EditCategory({ id: categoryId, data: formData }));
    setLoading(false);
  };

  // useEffect(() => {
  //   if (!loading && categoryRes && categories) {
  //     if (categoryRes.status === 201) {
  //       toast.success(
  //         isEdit
  //           ? "The category has been edited successfully"
  //           : "The category has been added successfully",
  //         {
  //           autoClose: 2000,
  //           className: "toast-success",
  //         }
  //       );
  //     } else if (categoryRes.status && categoryRes.status !== 201) {
  //       toast.error("Something went wrong", {
  //         autoClose: 2000,
  //         className: "toast-error",
  //       });
  //     }
  //   }
  // }, [loading && categoryRes && categories]);

  return {
    showModal,
    handleClose,
    handleShow,
    handleDeleteShow,
    handleDeleteClose,
    onNameChange,
    categoryName,
    handleAddCategory,
    handleEditCategory,
    handleConfirmEditCategory,
    isEdit,
    showDeleteModal,
    handleEditClose,
    selectedCategory,
    categories,
    handleDeleteCategory,
  };
};

export default CategoryHook;
