import React from "react";
import img from "../../assets/img/offies1.webp";
import { CalendarDate, Check2Square, Search } from "react-bootstrap-icons";

const View = () => {
  return (
    <section id="view" className="view container">
      <div className="row gy-4" data-aos="fade-up">
        <div className="view_txt col-lg-8">
          <h3 className="about-us-text">
            <Search className="view_icon" />
            رؤيتنا
          </h3>
          <p>
            أن نكون شركة رائدة بإدارة الاستثمار في تركيا والشرق الأوسط، وتطوير
            أدوات الاستثمار وأنواعه، ودعم الشركات الناشئة
          </p>

          <h3 className="about-us-text">
            <CalendarDate className="view_icon" />
            مهمتنا
          </h3>
          <p className="text-about-us">
            تأمين استثمار آمن للمساهمين،وابتكار الافكار الريادية في عالم الأعمال
            وتحويلها لشركات مساهمة، ودعم الشركات الناشئة بالاستشارات والتمويل
          </p>
          <h3 className="about-us-text">
            <Check2Square className="view_icon" />
            قِيَمُنَا
          </h3>
          <p className="text-about-us">مصداقية, شفافية, أمان , استدامة</p>
        </div>

        <div className="col-lg-4 view_img">
          <img src={img} alt="" />
        </div>
      </div>
    </section>
  );
};

export default View;
