import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CreateBlog } from "../../Redux/Actions/BlogActions";
import { toast } from "react-toastify";

const AddBlogHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BlogRes = useSelector((state) => state.BlogsData.BlogCreated);
  const [ArBlogTitle, setArBlogTitle] = useState("");
  const [TrBlogTitle, setTrBlogTitle] = useState("");
  const [ArBlogDesc, setArBlogDesc] = useState("");
  const [TrBlogDesc, setTrBlogDesc] = useState("");
  const [images, setImages] = useState([]);
  const [imageCover, setImageCover] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [selectedCatName, setSelectedCatName] = useState();
  const [selectedCatId, setSelectedCatId] = useState();
  const [selectedCat, setSelectedCat] = useState();

  const handleSelectCategory = (category) => {
    setSelectedCatId(category.split("_")[0]);
    setSelectedCatName(category.split("_")[1]);
    setSelectedCat(category);
  };

  // convert from base 64 to img file javascript
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // set Arabic State Values
  const onArBlogTitleChange = (e) => {
    e.persist();
    setArBlogTitle(e.target.value);
  };
  const onArBlogDescChange = (e, editor) => {
    const data = editor.getData();
    setArBlogDesc(data);
  };
  // const onArBlogDescChange = (e) => {
  //   e.persist();
  //   setArBlogDesc(e.target.value);
  // };
  // set Turkish State Values
  const onTrBlogTitleChange = (e) => {
    e.persist();
    setTrBlogTitle(e.target.value);
  };
  const onTrBlogDescChange = (e, editor) => {
    const data = editor.getData();
    setTrBlogDesc(data);
  };
  // const onTrBlogDescChange = (e) => {
  //   e.persist();
  //   setTrBlogDesc(e.target.value);
  // };
  // Modal control
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Add Blog Function
  const handleAddBlog = async (e) => {
    e.preventDefault();
    // convert Array of 64 images to file
    const itemImages = Array.from(Array(Object.keys(images).length).keys()).map(
      (item, index) => {
        return dataURLtoFile(images[index], Math.random() + ".png");
      }
    );

    let imageCoverFile = imageCover;
    if (typeof imageCover === "string" && imageCover.startsWith("data:")) {
      imageCoverFile = dataURLtoFile(imageCover, "cover.png");
    }

    const formData = new FormData();
    formData.append("titleAr", ArBlogTitle);
    formData.append("descriptionAr", ArBlogDesc);
    formData.append("titleTr", TrBlogTitle);
    formData.append("descriptionTr", TrBlogDesc);
    formData.append("imageCover", imageCoverFile);
    formData.append("categoryId", selectedCatId);
    formData.append("categoryName", selectedCatName);
    itemImages.map((img) => formData.append("images", img));
    setShow(false);
    await dispatch(CreateBlog(formData));
    setLoading(false);
  };
  useEffect(() => {
    if (Loading === false) {
      if (BlogRes?.status === 201) {
        toast.success("The Blog has been Added Succesfully", {
          autoClose: 2000,
          className: "toast-success",
        });
        setTimeout(() => {
          navigate("/Dashboard/Blogs");
        }, 2000);
      } else {
        toast.error("something went Wrong", {
          autoClose: 2000,
          className: "toast-error",
        });
      }
    }
  }, [Loading]);

  return [
    images,
    setImages,
    setImageCover,
    show,
    ArBlogTitle,
    ArBlogDesc,
    TrBlogTitle,
    TrBlogDesc,
    onArBlogTitleChange,
    onArBlogDescChange,
    onTrBlogTitleChange,
    onTrBlogDescChange,
    handleShow,
    handleClose,
    handleAddBlog,
    selectedCat,
    setSelectedCat,
    handleSelectCategory,
  ];
};

export default AddBlogHook;
