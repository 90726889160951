import HomePage from "./Pages/Home/HomePage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ServicesPage from "./Pages/Services/ServicesPage";
import ContactPage from "./Pages/Contact/ContactPage";
import BlogPage from "./Pages/Blog/BlogPage";
import DashboardPage from "./Pages/Dashboard/DashboardPage";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import "./assets/UserCss/css/main.css";
import "./assets/UserCss/css/Blogs/navigation.css";
import "react-toastify/dist/ReactToastify.css";
import AboutPage from "./Pages/About/AboutPage";
import BlogDetails from "./Components/BlogDetails/BlogDetails";
import AdminBlogsPage from "./Pages/Dashboard/Blogs/AdminBlogsPage";
import UpdateBlog from "./Components/Dashboard/Blogs/updateBlog";
import AddBlog from "./Components/Dashboard/Blogs/AddBlog";
import LoginPage from "./Pages/Auth/LoginPage";
import TurkishCitizenshiPage from "./Pages/TurkishCitizenshi/TurkishCitizenshiPage";
import AddCategory from "./Components/Dashboard/Categories/AddCategory";

function App() {
  // const [lang] = TranslatHook();
  const Token = localStorage.getItem("token");
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          {/* <Route path="/team" element={<TeamPage />} /> */}
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/Blogs" element={<BlogPage />} />
          <Route path="/Blogs/:id" element={<BlogDetails />} />
          {Token ? (
            <>
              <Route path="/Dashboard" element={<DashboardPage />} />
              <Route path="/Dashboard/Blogs" element={<AdminBlogsPage />} />
              <Route path="/Dashboard/Blogs/Add-Blog" element={<AddBlog />} />
              <Route path="/Dashboard/Category" element={<AddCategory />} />
              <Route path="/Dashboard/Blogs/:id" element={<UpdateBlog />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
          <Route path="/Login" element={<LoginPage />} />

          <Route
            path="/TurkishCitizenship"
            element={<TurkishCitizenshiPage />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
