import React from "react";
import img from "../../assets/img/meeting.webp";
import { Link } from "react-router-dom";

const AuthHeader = () => {
  return (
    <div
      className="breadcrumbs d-flex align-items-center"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="container position-relative d-flex flex-column align-items-center">
        <h2>تسجيل الدخول </h2>
        <ol>
          <li>
            <Link to="/">الرئيسية</Link>
          </li>
          <li>تسجيل الدخول </li>
        </ol>
      </div>
    </div>
  );
};

export default AuthHeader;
