import { createSlice } from "@reduxjs/toolkit";
import {
  AddCategory,
  getAllCategories,
  getOneCategory,
  DeleteCategory,
  EditCategory,
} from "../Actions/CategoryAction";

// Initial state
const initialState = {
  categories: [],
  getCategories: [],
  category: null,
  loading: false,
  error: null,
};

// Create the slice
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add Category
    builder.addCase(AddCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    });
    // Get All Categories
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.getCategories = action.payload;
    });

    // Get One Category
    builder.addCase(getOneCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.category = action.payload;
    });

    // Delete Category

    builder.addCase(DeleteCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    });

    // Edit Category

    builder.addCase(EditCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    });
  },
});

export default categorySlice.reducer;
