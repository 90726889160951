import React from "react";
import { Link } from "react-router-dom";
// import TranslatHook from "../../hook/translat-hook";
import data from "../../data";
import logo1 from "../../assets/img/logos/judywhite.webp";
import NavbarHook from "../../hook/navbar-hook";
import { ListUl, XCircle } from "react-bootstrap-icons";

const Navbar1 = () => {
  const [
    isSticked,
    isOpen,
    toggleMobileNav,
    isMobileNavActive,
    togoupand,
    togoupandDes,
  ] = NavbarHook();
  // const [lang, content] = TranslatHook();
  const [navbardata] = data();

  return (
    <header
      id="header"
      className={`header d-flex align-items-center fixed-top ${
        isSticked ? "sticked" : ""
      }`}
      style={{ direction: "rtl" }}
      // style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div
        className={`container-fluid container-xl d-flex align-items-center justify-content-between ${
          isMobileNavActive ? "mobile-nav-active" : ""
        }`}
      >
        {/* Logo */}
        <Link to="/" className="logo d-flex align-items-center">
          <img src={logo1} alt="Judy" />
        </Link>

        {/* Mobile Navigation Toggle */}
        <ListUl
          className={`mobile-nav-toggle mobile-nav-show bi bi-list ${
            !isOpen ? "" : "d-none"
          }`}
          style={{ position: "absolute", left: "20px" }}
          onClick={toggleMobileNav}
        />
        <XCircle
          className={`mobile-nav-toggle mobile-nav-hide bi bi-x ${
            isOpen ? "" : "d-none"
          }`}
          onClick={toggleMobileNav}
        />

        {/* Navigation Menu */}
        <nav
          id="navbar"
          className="navbar"
          style={
            isOpen
              ? {
                  height: "100vh",
                  position: "absolute",
                  right: "0px",
                  width: "100vw",
                  top: "0px",
                }
              : {}
          }
        >
          <ul
            style={
              isOpen
                ? {
                    height: "100%",
                    width: "100%",
                  }
                : {}
            }
          >
            {navbardata.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.link}
                  onClick={isMobileNavActive ? togoupand : togoupandDes}
                >
                  {item.Home}
                </Link>
              </li>
            ))}
            <li>
              <Link
                to="/contact"
                onClick={isMobileNavActive ? togoupand : togoupandDes}
              >
                {/* {content.Contact} */}
              </Link>
            </li>
            {/* Add translation component */}

            {/* {(!isMobileNavActive ? <Trans /> : <TransMobileTrans />)} */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar1;
