import React from "react";
import Navbar1 from "../../Components/Uitily/Navbar";
import AuthHeader from "../../Components/Auth/AuthHeader";
import Footer from "../../Components/Uitily/Footer";
import AuthForm from "../../Components/Auth/AuthForm";

const LoginPage = () => {
  return (
    <>
      <Navbar1 />
      <AuthHeader />
      <div className="sign-in" style={{ direction: "rtl" }}>
        <AuthForm />
      </div>
      <Footer />
    </>
  );
};

export default LoginPage;
