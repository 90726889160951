import React, { useState, useEffect } from "react";
import "../../assets/UserCss/css/sliderCountries.css";

import img from "../../assets/img/countrise/turky.webp";
import img2 from "../../assets/img/countrise/sudi.webp";
import img3 from "../../assets/img/countrise/dubai.webp";
import img4 from "../../assets/img/countrise/london.webp";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const INVESTEDCOUNTRIES = () => {
  const [images, setImages] = useState([img, img2, img3]);
  const [city, setCity] = useState([
    "Turkey",
    "Saudi Arabia",
    "United Kingdom",
  ]);

  useEffect(() => {
    const timer = setTimeout(changePhoto, 6000); // Change photo every 6 seconds
    const titletimer = setTimeout(changeCity, 6000);
    return () => {
      clearTimeout(timer); // Clean up the timer on component unmount
      clearTimeout(titletimer); // Clean up the timer on component unmount
    };
  }, [images, city]); // Re-run effect when images change

  const changePhoto = () => {
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages.push(newImages.shift()); // Rotate the images array
      return newImages;
    });
  };
  const changeCity = () => {
    setCity((prevCity) => {
      const newTitle = [...prevCity];
      newTitle.push(newTitle.shift());
      return newTitle;
    });
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);
  return (
    <div className="contanerall">
      <div className="section-header">
        <h2>الدول المستثمر فيها</h2>
      </div>
      {screenWidth < 640 ? (
        <>
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            <SwiperSlide>
              <div className="image-contaner">
                <img src={img} alt="img" />
                <div className="overlay">
                  <p className="image-text">تركيا</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image-contaner">
                <img src={img2} alt="img" />
                <div className="overlay">
                  <p className="image-text">المملكة العربية السعودية</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image-contaner">
                <img src={img3} alt="img" />
                <div className="overlay">
                  <p className="image-text">الامارات العربية المتحدة</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image-contaner">
                <img src={img4} alt="img" />
                <div className="overlay">
                  <p className="image-text">بريطانيا</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </>
      ) : (
        <div className="seccontaner">
          <div className="image-contaner">
            <img src={img} alt="img" />
            <div className="overlay">
              <p className="image-text">تركيا</p>
            </div>
          </div>

          <div className="image-contaner">
            <img src={img2} alt="img" />
            <div className="overlay">
              <p className="image-text">المملكة العربية السعودية</p>
            </div>
          </div>

          <div className="image-contaner">
            <img src={img3} alt="img" />
            <div className="overlay">
              <p className="image-text">الامارات العربية المتحدة</p>
            </div>
          </div>

          <div className="image-contaner">
            <img src={img4} alt="img" />
            <div className="overlay">
              <p className="image-text">بريطانيا</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default INVESTEDCOUNTRIES;
