import { configureStore } from "@reduxjs/toolkit";
import BlogsReducer from "./Slices/BlogSlice";
import AuthReducer from "./Slices/AuthSlice";
import CategoryReducer from "./Slices/CategorySlice";

const store = configureStore({
  reducer: {
    BlogsData: BlogsReducer,
    AuthData: AuthReducer,
    CategoryData: CategoryReducer,
  },
});

export default store;
