import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import userLogIn from "../../Redux/Actions/AuthActions";

const LogInHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthRes = useSelector((state) => state.AuthData.LoggedUser);

  // States
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);

  // Functions
  const onChangeUserName = (e) => {
    setUserName(e.target.value);
  };

  const onChangePassWord = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await dispatch(userLogIn({ email: userName, password: password }));
    setLoading(false);
  };

  useEffect(() => {
    if (loading === false) {
      if (AuthRes?.token) {
        localStorage.setItem("token", AuthRes?.token);
        navigate("/Dashboard");
      } else {
        localStorage.removeItem("token");
        toast.error("تأكد من اسم المستخدم أو كلمة المرور");
      }
    }
  }, [loading, AuthRes, navigate]);

  return [
    userName,
    password,
    onChangeUserName,
    onChangePassWord,
    onSubmit,
    loading,
  ];
};

export default LogInHook;
