import React from "react";
import BlogMine from "../../Components/Blog/BlogMine";
import BlogPost from "../../Components/Blog/BlogPost";
import Navbar1 from "../../Components/Uitily/Navbar";
import Footer from "../../Components/Uitily/Footer";

const BlogPage = () => {
  return (
    <>
      <Navbar1 />
      <div className="page-blog">
        <main id="main">
          <BlogMine />
          <section id="blog" className="blog pt-2">
            <div className="container" data-aos="fade-up">
              <BlogPost />
              {/* <BlogLeftSideSlide /> */}
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default BlogPage;
