import React from "react";
import CategoryHook from "../../../Hooks/CategoriesHook/CategoryHook";
import Sidenav from "../uitilis/Sidenav";
import AdminNavBar from "../uitilis/AdminNavBar";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "./Category.css";

const AddCategory = () => {
  const {
    showModal,
    handleClose,
    handleShow,
    handleDeleteShow,
    handleDeleteClose,
    onNameChange,
    categoryName,
    handleAddCategory,
    handleEditCategory,
    handleConfirmEditCategory,
    isEdit,
    showDeleteModal,
    handleEditClose,
    selectedCategory,
    categories,
    handleDeleteCategory,
  } = CategoryHook();

  return (
    <div>
      <div>
        <Sidenav />
      </div>
      <div className="D_container">
        <AdminNavBar />
        <div className="d-flex">
          {/* Add/Edit category */}
          <div dir="rtl" className="update-Blog col-5">
            <h1>{isEdit ? "تعديل التصنيف" : "إضافة تصنيف"}</h1>
            <div className="Blog-boxes justify-content-between">
              <div className="Blog-Ar">
                {/* Category title */}
                <label htmlFor="title">اسم التصنيف</label>
                <input
                  name="title"
                  value={categoryName}
                  onChange={onNameChange}
                  type="text"
                  className="input-form d-block mt-3 px-3"
                  placeholder="اسم التصنيف"
                />
              </div>
            </div>
            <Button
              className="btn-cat"
              variant="primary"
              onClick={isEdit ? handleShow : handleAddCategory}
            >
              {isEdit ? "تأكيد التعديل" : "تأكيد الإضافة"}
            </Button>

            {/* Add/Edit Confirmation Modal */}
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header>
                <Modal.Title>هل أنت متأكد؟</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {isEdit
                  ? "هل أنت متأكد من تعديل هذا التصنيف؟"
                  : "هل أنت متأكد من إضافة هذا التصنيف؟"}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-cat"
                  variant="secondary"
                  onClick={handleClose}
                >
                  إغلاق
                </Button>
                <Button
                  className="btn-cat"
                  variant="primary"
                  onClick={
                    isEdit ? handleConfirmEditCategory : handleAddCategory
                  }
                >
                  {isEdit ? "تعديل" : "إضافة"}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          {/* Categories List */}
          <div dir="rtl" className="update-Blog col-5">
            <h1>التصنيفات</h1>
            {categories?.data?.map((category) => {
              return (
                <div key={category._id} className="d-flex">
                  <p>{category.name}</p>
                  <span
                    className="me-3 text-primary cursor-pointer"
                    onClick={() => handleEditCategory(category)}
                  >
                    تعديل
                  </span>
                  <span
                    className="me-3 text-primary cursor-pointer"
                    onClick={() => handleDeleteShow(category)}
                  >
                    حذف
                  </span>
                </div>
              );
            })}
          </div>

          {/* Delete Confirmation Modal */}
          <Modal show={showDeleteModal} onHide={handleDeleteClose}>
            <Modal.Header>
              <Modal.Title>هل أنت متأكد؟</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              هل أنت متأكد من حذف التصنيف "{selectedCategory?.name}"؟
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-cat"
                variant="secondary"
                onClick={handleDeleteClose}
              >
                إغلاق
              </Button>
              <Button
                className="btn-danger"
                variant="danger"
                onClick={() => handleDeleteCategory(selectedCategory)}
              >
                حذف
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddCategory;
