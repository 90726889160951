import baseURL from "../../api/baseURL";

const useUpdateData = async (url, params) => {
  try {
    const res = await baseURL.put(url, params);
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const useUpdateDataWithImg = async (url, params) => {
  try {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const res = await baseURL.put(url, params, config);
    return res;
  } catch (error) {
    // Handle the error here, such as logging it or throwing a custom error
    console.error("Error occurred during PUT request:", error);
    throw error;
  }
};

export default useUpdateData;
