import React from "react";
import "../../../Components/Dashboard/Blogs/Blogs.css";
import Sidenav from "../../../Components/Dashboard/uitilis/Sidenav";
import AdminNavBar from "../../../Components/Dashboard/uitilis/AdminNavBar";
import AdminBlogPost from "../../../Components/Dashboard/Blogs/AdminBlogPost";
import AllBlogsHook from "../../../Hooks/BlogsHook/All-Blogs-Hook";
import BlogPagination from "../../../Components/Blog/BlogPagination";
import { Link } from "react-router-dom";
import { Plus } from "react-bootstrap-icons";

const AdminBlogsPage = () => {
  const [BlogsRes, PageCount, getBlogsWithPage] = AllBlogsHook();

  return (
    <div>
      <div>
        <Sidenav />
      </div>
      <div className="D_container">
        <AdminNavBar />
        <section id="blog" className="blog">
          <div className="Blogs-Page">
            <Link to={"/Dashboard/Blogs/Add-Blog"}>
              <button className="AddBlogBtn">
                <Plus size={30} color="#06515a" />
              </button>
            </Link>
            <div className="Blogs">
              {BlogsRes ? (
                BlogsRes.data?.map((blog, index) => (
                  <div className="Blog">
                    <AdminBlogPost key={index} blog={blog} />
                  </div>
                ))
              ) : (
                <h4>No Data to Show</h4>
              )}
            </div>

            {/* <BlogLeftSideSlide /> */}
          </div>
        </section>
        <BlogPagination PageCount={PageCount} onPress={getBlogsWithPage} />
      </div>
    </div>
  );
};

export default AdminBlogsPage;
