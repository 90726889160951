import React from "react";
import AboutMain from "../../Components/About/AboutMain";
import AboutSection from "../../Components/About/AboutSection";
import View from "../../Components/About/view";
import Navbar1 from "../../Components/Uitily/Navbar";
import Footer from "../../Components/Uitily/Footer";

const AboutPage = () => {
  return (
    <>
      <Navbar1 />
      <div className="page-about" style={{ direction: "rtl" }}>
        <main id="main">
          <AboutMain />
          <AboutSection />

          {/* <AboutWorkUs /> */}
          <View />
        </main>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
