import { useEffect, useState } from "react";

const NavbarHook = () => {
  //لتغير وضع شريط المهام في الاعلى ووضع لون في الخلفية
  const [isSticked, setIsSticked] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      //تستطيع تعديل القيمة للظهور في الوقت المناسب
      setIsSticked(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavActive(!isMobileNavActive);
    setIsOpen(!isOpen);
  };
  const togoupand = () => {
    setIsMobileNavActive(!isMobileNavActive);
    setIsOpen(!isOpen);
    if (isMobileNavActive) window.scrollTo({ top: 0 });
  };
  const togoupandDes = () => {
    setIsOpen(!isOpen);
    window.scrollTo({ top: 0 });
  };

  return [
    isSticked,
    isOpen,
    toggleMobileNav,
    isMobileNavActive,
    togoupand,
    togoupandDes,
  ];
};

export default NavbarHook;
