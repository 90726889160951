import React from "react";

const BenefitCard = ({ title, img }) => {
  return (
    <div dir="rtl" className="BenefitCard d-flex col-4 align-items-center">
      <img className="col-4" src={img} alt="" />
      <p className="col-7">{title}</p>
    </div>
  );
};

export default BenefitCard;
