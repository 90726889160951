import React, { useState } from "react";
// import BlogPagination from "../../Blog/BlogPagination";
import "./Blogs.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteBlog } from "../../../Redux/Actions/BlogActions";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { PencilSquare, Trash } from "react-bootstrap-icons";

const AdminBlogPost = ({ blog }) => {
  const dispatch = useDispatch();
  const BlogRes = useSelector((state) => state.BlogsData.BlogDeleted);
  // Modal control
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = async () => {
    setShow(false);
    await dispatch(DeleteBlog(blog._id));

    // Add a delay before checking the value of BlogRes
    setTimeout(() => {
      console.log(BlogRes);
      if (BlogRes) {
        toast("Deleted Succesfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }, 1000); // Adjust the delay time as needed
  };

  return (
    <div>
      <article className="Blog-card d-flex ">
        <div className="col-4 post-img">
          <img src={blog.imageCover} alt="" className="img-fluid" />
        </div>
        <div className="col-8 post-content">
          <h2 className="title">
            <Link to="/blog/blog-details">{blog.titleAr}</Link>
          </h2>

          <div className="meta-top">
            <ul>
              {/* <li className="d-flex align-items-center">
                <i className="bi bi-person"></i>{" "}
                <Link to="/blog/blog-details">judy investment</Link>
              </li> */}
              <li className="d-flex align-items-center">
                <i className="bi bi-clock"></i>{" "}
                <time datatime="2022-01-01">
                  {blog.createdAt.substring(0, 10)}
                </time>
              </li>
            </ul>
          </div>
          {/* 
          <div className="content">
            <p style={{ whiteSpace: "nowrap" }}>
              {parse(blog.descriptionAr.substring(0, 35))}...
            </p>
          </div> */}

          <div className="read-more d-flex">
            <button className="update_button col-4">
              <Link to={`/Dashboard/Blogs/${blog._id}`}>
                {" "}
                <PencilSquare color="#06515a" />
              </Link>
            </button>

            <button className="update_button col-4" onClick={handleShow}>
              <Trash color="#06515a" />
            </button>
          </div>
        </div>
      </article>
      {/* Delete Button Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Are you sure</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete this Blog ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default AdminBlogPost;
