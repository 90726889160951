import React, { useEffect, useState } from "react";
import img from "../../assets/img/citizinship/yatirim-nedir.webp";
import emailjs from "emailjs-com";
import "../../Pages/TurkishCitizenshi/style.css";
import { toast, ToastContainer } from "react-toastify";
import GeolocationComponent from "../../Components/TurkishCitizenship/Geolocation";

const ContactForm = () => {
  const { country, city, code } = GeolocationComponent();
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    phone: "",
    time: "",
    country: "",
    city: "",
  });

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      country: country || "",
      city: city || "",
      code: code || "",
    }));
    console.log(country, city, code);
  }, [country, city, code]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    e.preventDefault();
    emailjs
      .send(
        "service_lo2ljra",
        "template_p5nf9jv",
        formData,
        "W9so-JutQEoX1cAHF"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          toast.success(" لقد تم أرسال الرسالة ", {
            autoClose: 2000,
            className: "toast-success",
          });
        },
        (error) => {
          console.error("Error sending email:", error.text);
        }
      );
  };
  return (
    <form dir="rtl" onSubmit={handleSubmit}>
      <div className="row g-3">
        <div className="col-sm-6">
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={handleChange}
            required
            placeholder="الاسم"
          />
        </div>

        <div className="col-sm-6 d-flex">
          <input
            type="text"
            className="col-3 form-control"
            value={code}
            name="code"
            disabled
            required
            placeholder="الرمز"
            style={{ direction: "ltr", width: "20%" }}
          />
          <input
            type="text"
            className="col-8 form-control"
            name="phone"
            onChange={handleChange}
            required
            placeholder="الهاتف"
            style={{ direction: "ltr", textAlign: "end", width: "80%" }}
          />
        </div>

        <div className="col-12 ">
          <select
            id="time"
            name="time"
            onChange={handleChange}
            required
            style={{
              width: "100%",
              padding: "14px",
              border: "1px solid #dbdbdb",
              borderRadius: "5px",
            }}
          >
            <option selected disabled>
              اختر الوقت الذي يناسبك
            </option>
            <option>10:00 - 14:00</option>
            <option>14:00 - 18:00</option>
            <option>18:00 - 22:00</option>
          </select>
        </div>
        <div className="col-12 row bu">
          <button className="send_button col-6" type="submit">
            أرسل
          </button>
          <img className="col-6" src={img} alt="" />
        </div>
      </div>
      <ToastContainer />;
    </form>
  );
};

export default ContactForm;
